/*------------------------*/
input:focus,
button:focus,
.form-control:focus {
    outline: none;
    box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #fff;
}

/*----------step-wizard------------*/
.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

/*---------signup-step-------------*/
.bg-color {
    background-color: #333;
}

.signup-step-container {
    //padding: 24px 10px;
}




.wizard .nav-tabs {
    position: relative;
    margin-bottom: 0;
    border-bottom-color: transparent;
}

.wizard>div.wizard-inner {
    position: relative;
    margin-bottom: 50px;
    text-align: center;
}

.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 15px;
    z-index: 1;
}

.wizard .nav-tabs>li.active>a,
.wizard .nav-tabs>li.active>a:hover,
.wizard .nav-tabs>li.active>a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
}

#step2 .row .col-md-12 {
    border: 0.89246px solid #EDF0F3 !important;
}

#step2 .serBox {

    border: none;

}

#step3 .row .col-md-12 {
    border: 0.89246px solid #EDF0F3 !important;
}

#step3 .serBox {

    border: none;

}

#step4 .row .col-md-12 {
    border: 0.89246px solid #EDF0F3 !important;
}

#step4 .serBox {

    border: none;

}

#step6 .row .col-md-12 {
    border: 0.89246px solid #EDF0F3 !important;
}

#step6 .serBox {

    border: none;
}

#step7 .row .col-md-12 {
    border: 0.89246px solid #EDF0F3 !important;
}

#step7 .serBox {

    border: none;
}

#step8 .row .col-md-12 {
    border: 0.89246px solid #EDF0F3 !important;
}

#step8 .serBox {

    border: none;
}

#step9 .row .col-md-12 {
    border: 0.89246px solid #EDF0F3 !important;
}

#step9 .serBox {

    border: none;
}

#step5 .chkbuttone {
    background: white;
    Width: 170px;
    height: 40px !important;
    color: #556775;
    min-width: 180px;
    height: 58px;
    border-radius: 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 11.602px !important;
    line-height: 13px;
    border: 0.89246px solid #EDF0F3;
    /* display: flex; */
    align-items: center;
    text-align: center;
}

#step10 .chkbuttone {
    background: white;
    Width: 170px;
    height: 40px !important;
    color: #556775;
    min-width: 180px;
    height: 58px;
    border-radius: 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 11.602px !important;
    line-height: 13px;
    border: 0.89246px solid #EDF0F3;
    /* display: flex; */
    align-items: center;
    text-align: center;
}

.chkActiveBtn {
    background: rgba(253, 178, 0, 0.8) !important;
    color: white !important;
    border: 1px solid #F59C11 !important;

}

.btn-default {

    font-style: normal;
    font-weight: bold !important;
    font-size: 11.8544px !important;
    line-height: 17px;
    Width: 157px;
    Height: 40px;
    background: #F59C11 !important;
    box-shadow: 0px 8px 20px #DCE5F0;
    border-radius: 23.7087px;
}

.serBox {

    padding: 0px 0px !important;
}
.MuiFormControl-root>label.label {
   
    width: 100%;
   
}

.label {
    display: inline-block;
    background-color: red;
    width: 120px;
    height: 120px;
    padding: 5px 10px;
}

.radio:checked+#test {
    /* target next sibling (+) label */
    background-color: blue;
}

.highlight {
    background: #FDC133;
    color: white;
}

.activeBorder {
    border: solid 1px #fdc133 !important;
}

.highlight1 {
    background: white;
    color: black;
}

.activeBorder1 {
    border: solid 1px #BFD4E460 !important;
}

.btn-default-back {

    font-style: normal;
    font-weight: bold !important;
    font-size: 11.8544px !important;
    line-height: 17px;
    Width: 157px;
    Height: 40px;
    background: #E4E7EB !important;

    border-radius: 23.7087px !important;
}

.col-md-12 p {
    text-align: center;
    margin-top: 40px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11.602px;
    line-height: 22px;
}

 .ck-content>p {
    text-align: left;
    margin-top: 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11.602px;
    line-height: 22px;
}
 .ck-content>ul {
    list-style: disc !important;;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11.602px;
    line-height: 22px;
}

.ck-content>ul {
    margin: unset !important;
    padding-left: 30px !important;
}
.ck.ck-editor {
    width: 80% !important;
    display: inline-block !important;
  
}
.essChecklistquestions>p {
    text-align: left !important;
    margin-top: 0px !important;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11.602px;
    line-height: 22px;
}
 .essChecklistquestions>ul {
    list-style: disc !important;;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11.602px;
    line-height: 22px;
}

.essChecklistquestions>ul {
    margin: unset !important;
    padding-left: 30px !important;
}
span.round-tab {
    width: 12px;
    height: 12px;
    line-height: 30px;
    display: inline-block;
    border-radius: 50%;

    z-index: 2;
    position: absolute;
    top: 8px;
    left: 0;
    text-align: center;
    font-size: 16px;
    color: white;
    font-weight: 500;
    background: #FFFFFF;
    border: 1px solid #E4E7EB;
    box-sizing: border-box;
}

span.round-tab i {
    color: #555555;
}

.wizard li.done span.round-tab {
    background: #FDCF00;

}

.wizard-inner li.done:before {
    background: #FDCF00;
}

@-moz-document url-prefix() {
    .wizard li.active span.round-tab {

        top: 14px !important;

    }

    span.round-tab {
        top: 23px !important;
    }
}

.wizard li.active span.round-tab {
    background: #FDCF00;
    color: #fff;
    border-color: #FDCF00;
    height: 31px;
    width: 31px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 11.0292px;
    line-height: 28px;
    top: 0px;
    color: #FFFFFF;
}

.wizard li.active span.round-tab i {
    color: #5bc0de;
}

.wizard .nav-tabs>li.active>a i {
    color: #0db02b;
}

.wizard .nav-tabs>li {
    width: 6%;
}

.wizard-inner li:last-child:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 0%;
    height: 1px;

    background-color: #E4E7EB;

}

.wizard-inner li:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 6%;
    height: 1px;

    background-color: #E4E7EB;
}

.wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: red;
    transition: 0.1s ease-in-out;
}

.tab-pane h2 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 21.2266px;
    color: #4B4B4A;
}

.wizard .nav-tabs>li a {
    width: 30px;
    height: 30px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
    background-color: transparent;
    position: relative;
    top: 0;
}

.wizard .nav-tabs>li a i {
    position: absolute;
    top: -15px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: 700;
    color: #000;
}

.wizard .nav-tabs>li a:hover {
    background: transparent;
}

.wizard .tab-pane {
    position: relative;
    
}


.wizard h3 {
    margin-top: 0;
}

.prev-step,
.next-step {
    font-size: 13px;
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    margin-top: 0px;
}

.next-step {
    background-color: #0db02b;
}

.skip-btn {
    background-color: #cec12d;
}

.step-head {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
}

.term-check {
    font-size: 14px;
    font-weight: 400;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 40px;
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 40px;
    margin: 0;
    opacity: 0;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 40px;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 2;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 38px;
    padding: .375rem .75rem;
    line-height: 2;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
}

.footer-link {
    margin-top: 30px;
}

.all-info-container {}

.list-content {
    margin-bottom: 10px;
}

.list-content a {
    padding: 10px 15px;
    width: 100%;
    display: inline-block;
    background-color: #f5f5f5;
    position: relative;
    color: #565656;
    font-weight: 400;
    border-radius: 4px;
}

.list-content a[aria-expanded="true"] i {
    transform: rotate(180deg);
}

.list-content a i {
    text-align: right;
    position: absolute;
    top: 15px;
    right: 10px;
    transition: 0.5s;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #fdfdfd;
}

.list-box {
    padding: 10px;
}

.signup-logo-header .logo_area {
    width: 200px;
}

.signup-logo-header .nav>li {
    padding: 0;
}

.signup-logo-header .header-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-inline li {
    display: inline-block;
    padding: 7px;
}
.MuiFormLabel-root {

    background-color: unset;
    height: 0px;
   
    margin-bottom: 0px;
    
}
.MuiTypography-colorTextSecondary {
    margin-top: 0px !important;
}
.pull-right {
    float: right;
}

/*-----------custom-checkbox-----------*/
/*----------Custom-Checkbox---------*/
input[type="checkbox"] {
    position: relative;
    display: inline-block;
    margin-right: 5px;
}

input[type="checkbox"]::before,
input[type="checkbox"]::after {
    position: absolute;
    content: "";
    display: inline-block;
}

input[type="checkbox"]::before {
    height: 16px;
    width: 16px;
    border: 1px solid #999;
    left: 0px;
    top: 0px;
    background-color: #fff;
    border-radius: 2px;
}

input[type="checkbox"]::after {
    height: 5px;
    width: 9px;
    left: 4px;
    top: 4px;
}

input[type="checkbox"]:checked::after {
    content: "";
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: rotate(-45deg);
}

input[type="checkbox"]:checked::before {
    background-color: #18ba60;
    border-color: #18ba60;
}



#ossstep5chk label {
    display: inline-block;
background: white;

Width: 50px !important;
height: 40px !important;
color: #000000;


border-radius: 0px;
font-family: 'Open Sans', sans-serif;
font-style: normal;
font-weight: 600 !important;
font-size: 12px !important;
line-height: 58px;
border: 1px solid rgba(255, 204, 0, 0.25);
box-sizing: border-box;
/* display: flex; */
align-items: center;
text-align: center;;
}

#ossstep5chk label:hover {
    background-color: rgba(253, 178, 0, 0.8);
}

#ossstep5chk input[type="text"]:focus+label {
    border: 1px solid #F59C11;
}

#ossstep5chk input[type="text"]:checked+label {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    box-sizing: border-box;
    color: #FFFFFF;
}
#ossstep5chk input[type="text"] {
    opacity: 0;
    position: fixed;
    width: 0;
}



#checkBoxSubRadio label {
    display: inline-block;
    background: white;
    Width: 50px !important;
    height: 40px !important;
    color: #000000;
    border-radius: 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 58px;
    border: 1px solid rgba(255, 204, 0, 0.25);
    box-sizing: border-box;
    /* display: flex; */
    align-items: center;
    text-align: center;
}

#checkBoxSubRadio label:hover {
    background-color: rgba(253, 178, 0, 0.8);
}

#checkBoxSubRadio input[type="radio"]:focus+label {
    border: 1px solid #F59C11;
}

#checkBoxSubRadio input[type="radio"]:checked+label {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    box-sizing: border-box;
    color: #FFFFFF;
}
#checkBoxSubRadio input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}




#ossstep5chk label {
    display: inline-block;
background: white;

Width: 50px !important;
height: 40px !important;
color: #000000;


border-radius: 0px;
font-family: 'Open Sans', sans-serif;
font-style: normal;
font-weight: 600 !important;
font-size: 12px !important;
line-height: 58px;
border: 1px solid rgba(255, 204, 0, 0.25);
box-sizing: border-box;
/* display: flex; */
align-items: center;
text-align: center;;
}

#ossstep5chk label:hover {
    background-color: rgba(253, 178, 0, 0.8);
}

#ossstep5chk input[type="radio"]:focus+label {
    border: 1px solid #F59C11;
}

#ossstep5chk input[type="radio"]:checked+label {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    box-sizing: border-box;
    color: #FFFFFF;
}
#ossstep5chk input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

#step5chk1 input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

#step5chk1 input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

#step5chk1 label {
        display: inline-block;
    background: #F0F0F0;
    Width: 138px;
    height: 60px !important;
    color: #000000;
    min-width: 138px;
    height: 58px;
    border-radius: 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 58px;
    border: 1px solid rgba(255, 204, 0, 0.25);
    box-sizing: border-box;
    /* display: flex; */
    align-items: center;
    text-align: center;;
}

#step5chk1 label:hover {
    background-color: rgba(253, 178, 0, 0.8);
}

#step5chk1 input[type="radio"]:focus+label {
    border: 1px solid #F59C11;
}

#step5chk1 input[type="radio"]:checked+label {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    box-sizing: border-box;
    color: #FFFFFF;
}


#step5chk input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

#step5chk input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

#step5chk label {
    display: inline-block;
    background: white;
    Width: 138px;
    height: 60px !important;
    color: #000000;
    min-width: 138px;
    height: 58px;
    border-radius: 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 58px;
    border: 1px solid rgba(255, 204, 0, 0.25);
    box-sizing: border-box;
    /* display: flex; */
    align-items: center;
    text-align: center;;
}

#step5chk label:hover {
    background-color: rgba(253, 178, 0, 0.8);
}

#step5chk input[type="radio"]:focus+label {
    border: 1px solid #F59C11;
}

#step5chk input[type="radio"]:checked+label {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    box-sizing: border-box;
    color: #FFFFFF;
}

#step6chk input[type="radio"]:checked+label {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    box-sizing: border-box;
    color: #FFFFFF;
}

#step5chk input[type="checkbox"]:focus+label {
    border: 1px solid #F59C11;
}

#step5chk input[type="checkbox"]:checked+label {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    box-sizing: border-box;
    color: #FFFFFF;
}

#checkBoxDesign input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

#checkBoxDesign label {
    display: inline-block;
    background: white;
    Width: 100%;
    height: 40px !important;
    color: #556775;
    min-width: 180px;
    height: 58px;
    border-radius: 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 11.602px !important;
    line-height: 37px;
    border: 0.89246px solid #EDF0F3;
    /* display: flex; */
    align-items: center;
    text-align: center;
}



#checkBoxDesign input[type="checkbox"]:hover+label #test {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    color: #FFFFFF;
}

#checkBoxDesign input[type="checkbox"]:hover+label .serBox {
    border: 1px solid rgb(253, 193, 51);
}

#checkBoxDesign input[type="checkbox"]:checked+label #test {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    color: #FFFFFF;
}

#checkBoxDesign input[type="checkbox"]:checked+label .serBox {
    border: 1px solid rgb(253, 193, 51);

}


#checkBoxDesign input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
}


#ossStepRadio input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

#ossStepRadio label {
    display: inline-block;
    background: white;
    Width: 100%;
    height: 40px !important;
    color: #556775;
    min-width: 180px;
    height: 58px;
    border-radius: 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 11.602px !important;
    line-height: 37px;
    border: 0.89246px solid #EDF0F3;
    /* display: flex; */
    align-items: center;
    text-align: center;
}



#ossStepRadio input[type="radio"]:hover+label #test {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    color: #FFFFFF;
}

#ossStepRadio input[type="radio"]:hover+label .serBox {
    border: 1px solid rgb(253, 193, 51);
}

#ossStepRadio input[type="radio"]:checked+label #test {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    color: #FFFFFF;
}

#ossStepRadio input[type="radio"]:checked+label .serBox {
    border: 1px solid rgb(253, 193, 51);

}

#step3chk label {
    display: inline-block;
    background: white;
    Width: 100%;
    height: 40px !important;
    color: #556775;
    min-width: 180px;
    height: 58px;
    border-radius: 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 11.602px !important;
    line-height: 37px;
    border: 0.89246px solid #EDF0F3;
    /* display: flex; */
    align-items: center;
    text-align: center;
}



#step3chk input[type="radio"]:hover+label #test {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    color: #FFFFFF;
}

#step3chk input[type="radio"]:hover+label .serBox {
    border: 1px solid rgb(253, 193, 51);
}

#step3chk input[type="radio"]:checked+label #test {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    color: #FFFFFF;
}

#step3chk input[type="radio"]:checked+label .serBox {
    border: 1px solid rgb(253, 193, 51);

}
#step3chk input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}
#step3chk input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

#step3chk input[type="checkbox"]:hover+label #test {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    color: #FFFFFF;
}

#step3chk input[type="checkbox"]:hover+label .serBox {
    border: 1px solid rgb(253, 193, 51);
}

#step3chk input[type="checkbox"]:checked+label #test {
    background: rgba(253, 178, 0, 0.8);
    border: 1px solid #F59C11;
    color: #FFFFFF;
}

#step3chk input[type="checkbox"]:checked+label .serBox {
    border: 1px solid rgb(253, 193, 51);

}

@media (max-width: 767px) {
    .sign-content h3 {
        font-size: 40px;
    }

    .wizard .nav-tabs>li a i {
        display: none;
    }

    .signup-logo-header .navbar-toggle {
        margin: 0;
        margin-top: 8px;
    }

    .signup-logo-header .logo_area {
        margin-top: 0;
    }

    .signup-logo-header .header-flex {
        display: block;
    }
}

#market_operator input[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 50%;
    width: 16px;
    height: 16px;

    border: 2px solid #8A94A6;
    transition: 0.2s all linear;

}

#market_operator input[type="radio"]:checked {
    border: 5px solid #8A94A6;
    outline: unset !important
        /* I added this one for Edge (chromium) support */
}